<script setup lang="ts">
import { Error } from '@upa/design-system';

const router = useRouter();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const isLoading = ref(false);
const backToHome = () => {
  router.push(formatLink('/'));
  isLoading.value = true;
};
</script>

<template>
  <section>
    <!-- TODO: replace with approved loading icon -->
    <div
      v-if="isLoading"
      class="flex justify-center items-center loading-icon-container--height"
    >
      <div role="status">
        <div
          class="h-15 w-15 i-carbon-progress-bar-round animate-spin c-gray-300 flex justify-center"
        />
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <Error v-else @back-to-home="backToHome" />
    <!-- <h1 class="text-4xl font-bold mb-3">
      {{ $t("pageNotFound.header") }}
    </h1>
    <p class="text-sm mb-3">
      {{ $t("pageNotFound.text") }}
    </p>
    <NuxtLink
      class="inline-flex justify-center items-center py-2 px-4 text-base font-medium text-center text-white bg-brand-primary rounded-lg hover:bg-gray-400"
      :to="formatLink(`/`)"
    >
      {{ $t("backToHomepage") }}
    </NuxtLink> -->
  </section>
</template>

<style lang="scss" scoped>
.loading-icon-container--height {
  height: calc(100vh - 312px);

  @media ((min-width: 768px) and (max-width: 1024px)) {
    height: calc(100vh - 334px);
  }

  @media (max-width: 767px) {
    height: calc(100vh - 279px);
  }
}
</style>
